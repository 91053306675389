/**
 * eslint-disable @typescript-eslint/no-explicit-any
 *
 * @format
 */

/** @format */
import React from "react";
import { Button, Col, Row, Space } from "antd";
import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as eva from "eva-icons";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import style from "./Navbar.module.sass";
import axios from "axios";

interface Location {
  label: string;
  key: string;
  href?: string;
  children?: Location[];
  child?: Location[];
  acf?: any;
}

interface OrganizedLocations {
  [key: string]: {
    label: string;
    key: string;
    child: Location[];
  };
}

export default function NavbarDesktopV1() {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { t } = useTranslation("navbar");
  const [activeItem, setActiveItem] = useState<any>();
  const [activeDropdown, setActiveDropdown] = useState<any>();
  const [locationDropDown, setLocationDropDown] = useState<any>();
  const [organizedLocations, setOrganizedLocations] =
    useState<OrganizedLocations>({});
  const ohioCities = [
    "Cincinnati",
    "Cleveland",
    "Toledo",
    "Columbus",
    "Dayton",
  ];

  useEffect(() => {
    eva.replace();
  }, []);

  const fetchData = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/location/?_embed=&per_page=100`,
        method: "get",
      });
      setOrganizedLocations(organizeLocations(r?.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const organizeLocations = (data: Location[]): OrganizedLocations => {
    const states = ["Florida", "Ohio"];
    return data
      .filter((item) => item?.acf?.state && states.includes(item.acf.state))
      .reduce((acc: OrganizedLocations, item: any) => {
        const stateKey = item.acf.state.toLowerCase();
        if (!acc[stateKey]) {
          acc[stateKey] = { label: item.acf.state, key: stateKey, child: [] };
        }

        if (
          acc[stateKey] &&
          !(
            (item.title.rendered === "Winter Garden" &&
              stateKey === "florida") ||
            (stateKey === "ohio" && !ohioCities.includes(item.title.rendered))
          )
        ) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          acc[stateKey]!.child.push({
            label: item.title.rendered,
            key: item.slug,
            href: `/location/${item.slug}`,
          });
        }
        return acc;
      }, {});
  };

  const items: any = [
    {
      label: t("new_clients"),
      key: "new-clients",
      href: "/new-clients-intake-overview",
    },
    {
      label: t("practice_areas"),
      key: "practice_areas",
      children: [
        {
          label: t("all_practice_areas"),
          key: "all_practice_areas",
          href: "/practice-areas",
        },
        {
          label: t("tax_bankruptcy"),
          key: "tax_bankruptcy",
          href: "/taxbankruptcy",
        },
        {
          label: t("tax_controversy"),
          href: "/taxcontroversy",
          key: "tax_controversy",
        },
        {
          label: t("tax_compliance"),
          key: "tax_compliance_group",
          href: "/tax-compliance",
        },
        {
          label: t("criminal_tax"),
          key: "criminal_tax_defense",
          // disabled: false,
          href: "/criminal-tax-defense",
        },
        {
          label: <>{t("StateandLocalTax_tax")}</>,
          key: "stateandLocal",
          LocationsChild: [
            {
              label: t("florida_tax"),
              key: "floridaPractice_tax_defense",
              href: "/state-and-local-tax/florida-state-local",
            },
          ],
        }
      ],
    },
    {
      label: <>{t("about")}</>,
      key: "about-us",
      children: [
        {
          label: t("team"),
          key: "our-team",
          href: "/about-us/our-team",
        },
        {
          label: t("overview"),
          key: "firm-overview",
          href: "/about-us/firm-overview",
        },
        {
          label: t("practice_model"),
          key: "our-unique-practice-model",
          href: "/about-us/our-unique-practice-model",
        },
      ],
    },
    {
      label: <>{t("resources")}</>,
      key: "resources",
      theme: "light",
      children: [
        {
          key: "articles",
          label: t("articles"),
          href: "/resources/articles",
        },
        // {
        //   key: "news",
        //   label: t("news"),
        //   href: "/resources/news",
        // },
        {
          key: "blogs",
          label: t("blog"),
          href: "/resources/blog",
        },

        {
          key: "tax_resource_link",
          label: t("resource_links"),
          href: "/resources/tax-resource-links",
        },
        {
          key: "video_library",
          label: t("video"),
          href: "/resources/video-library",
        },
        {
          key: "press_releases",
          label: t("press"),
          href: "/resources/press-releases",
        },
      ],
    },
    {
      label: <>{t("faqs")}</>,
      key: "faqs",
      href: "/faqs",
    },
    {
      label: <>{t("contact")}</>,
      key: `contact`,
      children: [
        {
          label: <>{t("contact")}</>,
          key: "contact-us",
          href: "/contact-us",
        },
        {
          label: <>{t("locations")}</>,
          key: "locations",
          LocationsChild: [
            {
              label: "Florida",
              key: "florida",
              children: organizedLocations["florida"]
                ? organizedLocations["florida"]?.child
                : [],
            },
            {
              label: "Ohio",
              key: "ohio",
              children: organizedLocations["ohio"]
                ? organizedLocations["ohio"].child
                : [],
            },
          ],
        },
      ],
    },
  ];

  console.log("locationDropDown", locationDropDown);

  return (
    <>
      <Row justify={"space-around"} style={{ margin: "auto" }}>
        <Col span={22}>
          <Row justify='space-between' align={"middle"}>
            <Col span={12} style={{ textAlign: "left" }}>
              <div className='imageContainer'>
                <Link href='/'>
                  <Image
                    width={530}
                    height={349}
                    src='/TWGUpdatedLogo.png'
                    alt='TWG-Logo'
                    className={style.imageWrapper}
                    priority
                  />
                </Link>
              </div>
            </Col>
            <Col span={12} style={{ fontSize: 20, textAlign: "right" }}>
              <Space align='center'>
                <>
                  <i
                    style={{ cursor: "pointer" }}
                    data-eva='phone-call'
                    data-eva-height='20'
                    data-eva-fill='#005BA2'
                    data-eva-animation='pulse'
                  />
                  <a
                    href='tel:8662829333'
                    style={{ color: "#000", fontSize: 20, marginRight: 15 }}>
                    (866) 2TaxDefense
                  </a>
                </>
                <Button
                  size='large'
                  href='https://app.clio.com/client_connect'
                  target='_blank'
                  type='primary'
                  style={{
                    paddingLeft: 28,
                    paddingRight: 28,
                    marginRight: 10,
                  }}>
                  {t("client_portal")}
                </Button>
                <Button
                  size='large'
                  style={{ paddingLeft: 28, paddingRight: 28 }}
                  type='primary'
                  onClick={() =>
                    router.push({ pathname, query }, asPath, {
                      locale: locale == "es" ? "en" : "es",
                    })
                  }>
                  {locale == "es" ? "English" : "Español"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ backgroundColor: "#254486" }} justify='center'>
        {/* <Col {...responsiveColumn([18, 18, 20, 22, 23, 23])}> */}
        <Col>
          {/* <Menu
            items={items}
            mode="horizontal"
            theme="dark"
            style={{
              justifyContent: "space-around",
              backgroundColor: "#254486",
              color: "#fff",
            }}
          /> */}

          <div className="topnav" id="myTopnav">
            {items?.map((item: any) => {
              if (!item.children)
                return (
                  <Link href={item.href} key={item.key} locale={locale}>
                    {item.label}
                  </Link>
                );
              else
                return (
                  <div
                    className='dropdown'
                    key={item.key}
                    onMouseOver={() => setActiveItem(item.key)}
                    onMouseOut={() => setActiveItem("")}>
                    <a href={item?.href}>
                      {item.label}
                      <div style={{ display: "inline-block", marginLeft: 10 }}>
                        {activeItem != item.key ? (
                          <Image
                            style={{
                              width: 10,
                              height: 7,
                              marginLeft: 10,
                              marginBottom: 5,
                            }}
                            width={20}
                            height={14}
                            src='/down_outlined.png'
                            alt='down'
                          />
                        ) : (
                          <Image
                            style={{
                              width: 10,
                              height: 7,
                              marginLeft: 10,
                              marginBottom: 5,
                            }}
                            width={20}
                            height={14}
                            src='/up_outlined.png'
                            alt='up'
                          />
                        )}
                      </div>
                    </a>

                    <div className='dropdown-content'>
                      {item.children?.map((child: any) => {
                        return child.href ? (
                          <Link
                            key={child.key}
                            href={child.href}
                            locale={locale}>
                            {child.label}
                          </Link>
                        ) : child.LocationsChild ? (
                          <div
                            className=''
                            key={child.key}
                            onMouseOver={() => setActiveDropdown(child.key)}
                            onMouseOut={() => setActiveDropdown("")}>
                            <a
                              href={child?.href}
                              className=''
                              style={{
                                display: "flex",
                                pointerEvents: !child.href ? "none" : "auto",
                              }}>
                              {child.label}
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: 10,
                                }}>
                                {activeDropdown != child.key ? (
                                  <Image
                                    style={{
                                      width: 10,
                                      height: 7,
                                      marginLeft: 10,
                                      marginBottom: 5,
                                    }}
                                    width={20}
                                    height={14}
                                    src='/down_outlined.png'
                                    alt='down'
                                  />
                                ) : (
                                  <Image
                                    style={{
                                      width: 10,
                                      height: 7,
                                      marginLeft: 10,
                                      marginBottom: 5,
                                    }}
                                    width={20}
                                    height={14}
                                    src='/up_outlined.png'
                                    alt='up'
                                  />
                                )}
                              </div>
                            </a>

                            {activeDropdown && (
                              <Row style={{ width: 325 }}>
                                {item.children?.map((child: any, index: React.Key | null | undefined) => {
                                    return (
                                      child.LocationsChild && (
                                        <React.Fragment key={index}>
                                          {child.LocationsChild?.map((ContactLocations: any, j: number) => {
                                              return ContactLocations.label ? (
                                                <>
                                                  <Col
                                                    span={12}
                                                    className={style.bocaRatonDropdown}
                                                    key={ContactLocations.key}
                                                    >
                                                    <Link
                                                      style={{ lineHeight: 1.5 }}
                                                      locale={locale}
                                                      onMouseOver={() => {
                                                        setLocationDropDown(ContactLocations);
                                                      }}
                                                      onMouseOut={() => setLocationDropDown("")}
                                                      href={ContactLocations?.href  ? ContactLocations?.href  : ""}
                                                      >
                                                      <Image
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                        width={15}
                                                        height={18}
                                                        src='/location.png'
                                                        alt='big logo'
                                                      />
                                                      {ContactLocations.label}
                                                      {!!ContactLocations?.children ? (
                                                        locationDropDown?.key !=
                                                        ContactLocations.key ? (
                                                          <Image
                                                            style={{
                                                              width: 10,
                                                              height: 7,
                                                              marginLeft: 8,
                                                              marginBottom: 5,
                                                            }}
                                                            width={20}
                                                            height={14}
                                                            src='/down_outlined.png'
                                                            alt='down'
                                                          />
                                                        ) : (
                                                          <Image
                                                            style={{
                                                              width: 10,
                                                              height: 7,
                                                              marginLeft: 8,
                                                              marginBottom: 5,
                                                            }}
                                                            width={20}
                                                            height={14}
                                                            src='/up_outlined.png'
                                                            alt='up'
                                                          />
                                                        )
                                                      ) : null}
                                                    </Link>

                                                    {j === 0 &&
                                                      locationDropDown?.key &&
                                                      !!locationDropDown?.children && (
                                                        <Row
                                                          style={{
                                                            width: 325,
                                                          }}>
                                                          {(
                                                            locationDropDown?.children ??
                                                            []
                                                          )?.map(
                                                            (item: any) => {
                                                              return (
                                                                <Col
                                                                  span={12}
                                                                  className={
                                                                    style.bocaRatonDropdown
                                                                  }
                                                                  onMouseOver={() => {
                                                                    setLocationDropDown(
                                                                      locationDropDown
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    zIndex: 1000,
                                                                  }}
                                                                  key={
                                                                    item.key
                                                                  }>
                                                                  <Link
                                                                    style={{
                                                                      lineHeight: 1.5,
                                                                    }}
                                                                    href={
                                                                      item.href
                                                                    }
                                                                    locale={
                                                                      locale
                                                                    }>
                                                                    <Image
                                                                      style={{
                                                                        marginRight:
                                                                          "5px",
                                                                      }}
                                                                      width={15}
                                                                      height={
                                                                        18
                                                                      }
                                                                      src='/location.png'
                                                                      alt='big logo'
                                                                    />
                                                                    {item.label}
                                                                  </Link>
                                                                </Col>
                                                              );
                                                            }
                                                          )}
                                                        </Row>
                                                      )}

                                                    {!!ContactLocations?.child && (
                                                      <div
                                                        className={
                                                          style.customScrollable
                                                        }>
                                                        {(
                                                          ContactLocations?.child ||
                                                          []
                                                        )?.map((item: any) => (
                                                          <Link
                                                            href={item.href}
                                                            key={item.key}>
                                                            {item.label}
                                                          </Link>
                                                        ))}
                                                      </div>
                                                    )}
                                                  </Col>
                                                </>
                                              ) : null;
                                            }
                                          )}
                                        </React.Fragment>
                                      )
                                    );
                                  }
                                )}
                              </Row>
                            )}
                          </div>
                        ) : (
                          <span key={child.key} className='disabled_menu_item'>
                            {child.label}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                );
            })}
          </div>
        </Col>
      </Row>
    </>
    // </div>
  );
}
