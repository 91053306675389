/** @format */

import { Box } from "@mui/material";
import NavbarDesktopV1 from "./NavbarDesktopV1";
import NavbarMobile from "./NavbarMobile";

export default function Navbar() {
	return (
		<Box
      sx={{
        height: 0,
        mb: "clamp(103px, 8vw, 120px)", // Default clamp value for smaller screens
        "@media (min-width: 425px)": {
          mb: "clamp(108px, 10vw, 130px)", // Adjust for tablets and larger screens
        },
        "@media (min-width: 768px)": {
          mb: "clamp(126px, 10vw, 130px)", // Adjust for tablets and larger screens
        },
        "@media (min-width: 1200px)": {
          mb: "clamp(100px, 12vw, 150px)", // Adjust for larger screens
        },
      }}
    >
			<Box
				sx={{ display: { xs: "none", lg: "block" } }}
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					zIndex: 999,
					width: "100%",
					background: "#fff",
				}}>
				<NavbarDesktopV1 />
			</Box>
			<Box
				sx={{
					display: { xs: "block", lg: "none" },
					position: "fixed",
					top: 0,
					left: 0,
					zIndex: 999,
					background: "#fff",
					width: "100%",
					"@media (min-width: 1200px)": {
						display: "none",
					},
				}}>
				<NavbarMobile />
			</Box>
		</Box>
	);
}
