export function getFromURL({ url }: { url: URL }) {
  return url;
  // Do something with the pathname and return the result
}

export function replaceSpacesAndHyphensWithUnderscores(inputString: string) {
  const outputString: string = inputString.replace(/[-\s]/g, "_");
  return outputString;
}

export type LSSDataType = {
  city: string;
  keyword: string;
  slug: string;
};
