/**
 * eslint-disable @typescript-eslint/no-explicit-any
 *
 * @format
 */

import { MenuOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import { Button, Col, Drawer, Menu, Row, Space } from "antd";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import style from "./Navbar.module.sass";
import { useTranslation } from "next-i18next";
import axios from "axios";

interface Location {
  acf: {
    state: string;
  };
  title: {
    rendered: string;
  };
  slug: string;
}

interface ChildLocation {
  label: JSX.Element | string; // Allow JSX Elements for embedding links
  key: string;
  href?: string; // href might be optional if you use other types of children
}

interface OrganizedState {
  label: string;
  key: string;
  theme: string;
  children: ChildLocation[];
}

interface OrganizedLocations {
  [key: string]: OrganizedState;
}

export default function NavbarMobile() {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { t } = useTranslation("navbar");

  const [menuOpen, setMenuOpen] = useState(false);
  const [openKey, setOpenKey] = useState<string[] | undefined>(undefined);
  const [organizedLocations, setOrganizedLocations] =
    useState<OrganizedLocations>({});

  const organizeLocations = (data: Location[]): OrganizedLocations => {
    const states = ["Florida", "Ohio"];
    const ohioCities = [
      "Cincinnati",
      "Cleveland",
      "Toledo",
      "Columbus",
      "Dayton",
    ];

    return data
      ?.filter((item) => states.includes(item.acf.state)) // Filter by state
      ?.reduce((acc: OrganizedLocations, item: Location) => {
        const stateKey = item.acf.state.toLowerCase();
        const stateName = item.acf.state;

        if (!acc[stateKey]) {
          acc[stateKey] = {
            label: stateName,
            key: stateKey,
            theme: "light",
            children: [],
          };
        }

        if (stateKey === "florida" && item.title.rendered === "Winter Garden") {
          return acc;
        }

        if (stateKey === "ohio" && !ohioCities.includes(item.title.rendered)) {
          return acc;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        acc[stateKey]!.children.push({
          label: (
            <Link href={`/location/${item.slug}`} className='linkTagColor'>
              {item.title.rendered}
            </Link>
          ),
          key: item.slug,
        });

        return acc;
      }, {});
  };

  const fetchData = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/location/?_embed=&per_page=100`,
        method: "get",
      });
      setOrganizedLocations(organizeLocations(r?.data));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const subLocationTitileClickHandler = (url: string) => {
    setMenuOpen(false);
    router.push(url);
  };

  const items: any = [
    {
      label: (
        <Link href={"/new-clients-intake-overview"} className='linkTagColor'>
          {t("new_clients")}
        </Link>
      ),
      key: "new-clients",
    },
    {
      label: t("practice_areas"),
      key: "practice_areas",
      children: [
        {
          label: (
            <Link href={"/practice-areas"} className='linkTagColor'>
              {t("all_practice_areas")}
            </Link>
          ),
          key: "all_practice_areas",
        },
        {
          label: (
            <Link href={"/taxbankruptcy"} className='linkTagColor'>
              {t("tax_bankruptcy")}
            </Link>
          ),
          key: "tax_bankruptcy",
        },
        {
          label: (
            <Link href={"/taxcontroversy"} className='linkTagColor'>
              {t("tax_controversy")}
            </Link>
          ),
          key: "tax_controversy",
        },
        {
          label: (
            <Link href={"/tax-compliance"} className='linkTagColor'>
              {t("tax_compliance")}
            </Link>
          ),
          key: "tax_compliance_group",
        },
        {
          label: (
            <Link href={"/criminal-tax-defense"} className='linkTagColor'>
              {t("criminal_tax")}
            </Link>
          ),
          key: "criminal_tax_defense",
        },
        {
          label: <>{t("StateandLocalTax_tax")}</>,
          key: "stateandLocal",
          theme: "light",
          children: [
            {
              label: (
                <Link href={"/state-and-local-tax/florida-state-local"} className='linkTagColor'>
                  {t("florida_tax")}
                </Link>
              ),
              key: "floridaPractice_tax_defense",
            },
          ],
        },
      ],
    },
    {
      label: <>{t("about")}</>,
      key: "about-us",
      children: [
        {
          label: (
            <Link href={"/about-us/our-team"} className='linkTagColor'>
              {t("team")}
            </Link>
          ),
          key: "our-team",
        },
        {
          label: (
            <Link href={"/about-us/firm-overview"} className='linkTagColor'>
              {t("overview")}
            </Link>
          ),
          key: "firm-overview",
        },
        {
          label: (
            <Link
              href={"/about-us/our-unique-practice-model"}
              className='linkTagColor'>
              {t("practice_model")}
            </Link>
          ),
          key: "our-unique-practice-model",
        },
      ],
    },
    {
      label: <>{t("resources")}</>,
      key: "resources",
      theme: "light",
      children: [
        {
          key: "articles",
          label: (
            <Link href={"/resources/articles"} className='linkTagColor'>
              {t("articles")}
            </Link>
          ),
        },
        {
          key: "blogs",
          label: (
            <Link href={"/resources/blog"} className='linkTagColor'>
              {t("blog")}
            </Link>
          ),
        },

        {
          key: "tax_resource_link",
          label: (
            <Link
              href={"/resources/tax-resource-links"}
              className='linkTagColor'>
              {t("resource_links")}
            </Link>
          ),
        },
        {
          key: "video_library",
          label: (
            <Link href={"/resources/video-library"} className='linkTagColor'>
              {t("video")}
            </Link>
          ),
        },
        {
          key: "press_releases",
          label: (
            <Link href={"/resources/press-releases"} className='linkTagColor'>
              {t("press")}
            </Link>
          ),
        },
      ],
    },
    {
      label: (
        <Link href={"/faqs"} className='linkTagColor'>
          {t("faqs")}
        </Link>
      ),
      key: "faqs",
    },
    {
      label: <>{t("contact")}</>,
      key: "contact-us-main",
      theme: "light",
      children: [
        {
          label: (
            <Link href={"/contact-us"} className='linkTagColor'>
              {t("contact")}
            </Link>
          ),
          key: "contact-us",
        },
        {
          label: <>{t("locations")}</>,
          key: "locations",
          theme: "light",
          children: [
            {
              label: "Florida",
              key: "florida",
              theme: "light",
              children: organizedLocations.florida
                ? organizedLocations.florida.children
                : [],
            },
            {
              label: "Ohio",
              key: "ohio",
              theme: "light",
              children: organizedLocations.ohio
                ? organizedLocations.ohio.children
                : [],
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Drawer title='MENU' open={menuOpen} onClose={() => setMenuOpen(false)}>
        <Menu
          items={items}
          mode='inline'
          onSelect={() => {
            setMenuOpen(false);
          }}
          openKeys={openKey}
          onOpenChange={(keys) => {
            setOpenKey(keys);
          }}
        />
      </Drawer>
      <Box className={style.mobileNavHolder}>
        <Link
          href='/'
          className={style.mobileLogo}
          onClick={() => {
            setOpenKey(undefined);
            setMenuOpen(false);
          }}>
          <Image
            width={530}
            height={349}
            src='/TWGUpdatedLogo.png'
            alt='TWG-Logo'
            className={style.imageWrapper}
            priority
          />
        </Link>

        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
          <Button
            size='small'
            block
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: "45px",
              height: "30px",
              color: "#254486",
              border: "1px solid  #254486",
            }}
            onClick={() =>
              router.push({ pathname, query }, asPath, {
                locale: locale == "es" ? "en" : "es",
              })
            }>
            {locale == "es" ? "Eng" : "Es"}
          </Button>
          <MenuOutlined
            onClick={() => setMenuOpen(true)}
            style={{
              fontSize: 18,
              fontWeight: 900,
              cursor: "pointer",
              color: "#254486",
            }}
          />
        </div>
      </Box>
      <Row
        justify='center'
        align='middle'
        style={{
          color: "#fff",
          backgroundColor: "#254486",
          textAlign: "center",
          height: 40,
        }}>
        <Col
          style={{
            backgroundColor: "#254486",
            fontSize: 15,
            padding: "10px 0",
            cursor: "pointer",
            borderRight: "1px #fff solid",
          }}
          span={11}>
          <Space>
            <i
              style={{ cursor: "pointer" }}
              data-eva='phone-call'
              data-eva-height='16'
              data-eva-fill='#fff'
              data-eva-animation='pulse'
            />
            <a href='tel:8662829333' style={{ fontSize: 14, color: "#fff" }}>
              (866) 2TaxDefense
            </a>
          </Space>
        </Col>
        <Col
          style={{
            backgroundColor: "#254486",
            padding: "10px 0",
            textAlign: "center",
            fontSize: 14,
            cursor: "pointer",
            borderLeft: "1px #fff solid",
          }}
          span={11}>
          <a
            href=' https://app.clio.com/client_connect '
            target='_blank'
            style={{ color: "#fff" }}>
            Client Portal
          </a>
        </Col>
      </Row>
    </>
  );
}
