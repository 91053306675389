/* eslint-disable @next/next/next-script-for-ga */
/** @format */
import { Col, ConfigProvider, Layout, Row } from "antd";
import "antd/dist/reset.css";
import { appWithTranslation } from "next-i18next";
import { type AppType } from "next/dist/shared/lib/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { getFromURL } from "../components/utils";
import CustomLiveChat from "../components/widgets/CustomLiveChat";
import { AppWrapper } from "../context/CommonContext";
import "../styles/globals.css";

const MyApp: AppType = ({ Component, pageProps }) => {
	const [loading, setLoading] = useState(true);
	// const [thanku, setthanku] = useState("");
	const router = useRouter();
	const hideNavbarFooter =
		router.pathname === "/financial-information-statement-form" || router.pathname === "/financial-information-statement-form-b";
	useEffect(() => {
		setLoading(false);
	}, []);
	useEffect(() => {
		// Assuming router is imported and contains the current route information
		const baseUrl = "https://example.com"; // Replace with your actual base URL
		const url = new URL(baseUrl + router.pathname);

		// Call the getFromURL function and store the result directly in localStorage
		localStorage.setItem("router", getFromURL({ url }).toString());
	}, [router]);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	return (
		<>
			<div style={{ opacity: loading ? 0 : 1 }}>
				<AppWrapper>
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: "#254486",
								borderRadius: 4,
								fontWeightStrong: 400,
								colorBgLayout: "#fff",
								fontFamily: "'Raleway', sans-serif",
							},
						}}>
						<Layout className='raleway'>
							{!hideNavbarFooter && (
								<Row style={{ backgroundColor: "#fff" }}>
									<Col span={24}>
										{router.pathname !==
											"lp/tax-bankruptcy-attorney-florida" && <Navbar />}
									</Col>
								</Row>
							)}

							<Row
								style={
									{
										// marginTop: !hideNavbarFooter
										// 	? screenWidth.xl
										// 		? 185
										// 		: 110
										// 	: "",
									}
								}>
								<Col span={24}>
									<Component {...pageProps} />
								</Col>
							</Row>
							{!hideNavbarFooter && <CustomLiveChat />}
						</Layout>
					</ConfigProvider>
				</AppWrapper>
			</div>
		</>
	);
};

export default appWithTranslation(MyApp);
