/* eslint-disable @typescript-eslint/no-explicit-any */
/** @format */

import { LiveChatWidget, useWidgetState } from "@livechat/widget-react";
import Image from "next/image";

export default function CustomLiveChat() {
  const liveChatState = useWidgetState();

  return (
    <>
      <Image
        src='/start_chat.svg'
        id='custom-live-chat'
        className={liveChatState?.visibility}
        width='84'
        height='84'
        alt='start live chat'
        onClick={() => (window as any)?.LC_API.open_chat_window()}
      />
      <LiveChatWidget license={"14441892"} visibility='hidden' />
    </>
  );
}
