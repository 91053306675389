/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import type { ScriptProps } from "next/script";
import { createContext, useContext, useState } from "react";

type CommonContextType = {
  newClientFormData: any;
  setNewClientFormData: any;
  email: string | null;
  setEmail: (email: string | null) => void;
  phone_number: number | null;
  setPhoneNumber: (phone_number: number | null) => void;
  backLink: string | null;
  setBackLink: (backLink: string | null) => void;
  customThankYouPageMsg: string | null;
  setCustomThankYouPageMsg: (customThankYouPageMsg: string | null) => void;
};

const CommonContext = createContext<CommonContextType>({
  newClientFormData: null,
  setNewClientFormData: () => {},
  email: null,
  setEmail: () => {},
  phone_number: null,
  setPhoneNumber: () => {},
  backLink: null,
  setBackLink: () => {},
  customThankYouPageMsg: null,
  setCustomThankYouPageMsg: () => {},
});

export function AppWrapper({ children }: ScriptProps) {
  const [newClientFormData, setNewClientFormData] = useState();
  const [email, setEmail] = useState<string | null>(null);
  const [phone_number, setPhoneNumber] = useState<number | null>(null);
  const [backLink, setBackLink] = useState<string | null>(null);
  const [customThankYouPageMsg, setCustomThankYouPageMsg] = useState<
    string | null
  >(null);

  const sharedState = {
    newClientFormData: newClientFormData,
    setNewClientFormData: setNewClientFormData,
    email: email,
    setEmail: setEmail,
    phone_number: phone_number,
    setPhoneNumber: setPhoneNumber,
    backLink: backLink,
    setBackLink: setBackLink,
    customThankYouPageMsg: customThankYouPageMsg,
    setCustomThankYouPageMsg: setCustomThankYouPageMsg,
  };

  return (
    <CommonContext.Provider value={sharedState}>
      {children}
    </CommonContext.Provider>
  );
}

export function useCommonContext() {
  return useContext(CommonContext);
}
